import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import * as styles from './club-articles-section.module.scss'
import ArticlePreview, { ArticlePreviewProps } from "../../molecules/article-preview/article-preview"
import Advertisement, {classOptions} from "../../../advertisments-module/atoms/advertisement/advertisement"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ExtraAd from "../../../advertisments-module/organisms/extra-ad/extra-ad"
import EgPower from '../../../advertisments-module/organisms/eg-power/eg-power'
import LoadMoreArticles from "../../../core-module/molecules/load-more-articles/load-more-articles"

const ClubArticlesSection = ({ posts, advertisement, entityId, entityType, adsRandmon, pageCount }) => {
    const [firstRow] = useState(() => posts.slice(0, 2));
    const [otherRows, setOtherRows] = useState(() => posts.slice(2, posts.length))
    const outterClass = { title: styles.articleTitle }

    const properties = {
        entityId,
        entityType,
        pageCount
    }

    const attachPosts = (data) => {
        setOtherRows((otherRows) => (
            [...otherRows, ...data]
        ))
    }

    return (
        <div className={`container ${styles.content}`}>
            <div className={styles.main}>
                <div className={styles.articles}>
                    <div className={styles.firstRow}>
                        {firstRow.map((item, index) => (
                            <div key={`randonPost_vertical${index}`} className={styles.firstRow__item}>
                                <ArticlePreview article={item} />
                            </div>
                        ))}
                    </div>
                    <EgPower entityId={entityId}/>
                    <div className={styles.secondRow}>
                        <div className={styles.secondRow__posts}>
                            {otherRows?.map((item, index) => (
                                <div key={`randonPost_horizontal${index}`} className={styles.secondRow__posts__item}>
                                    <ArticlePreview article={item} horizontal={true} outterClass={outterClass} />
                                </div>
                            ))}
                            <LoadMoreArticles {...properties} attachNewArticles={attachPosts}/>
                        </div>
                    </div>
                </div>
                <div className={styles.advertisement}>
                    
                    
                    <div className={styles.secondRow__advertisement}>
                     { adsRandmon && <Advertisement mediaUrl={adsRandmon.media_link}
                            addUUID={`${adsRandmon.id}-landing-${adsRandmon.position}`}
                            destinationLink={adsRandmon.url}
                            mediaType={adsRandmon.media_type}
                            campaignId={adsRandmon.campaignId} 
                            classOption={adsRandmon.advertisementRandom}/> }
                    </div>
                    <ExtraAd />
                    {!!advertisement && <Advertisement campaignId={advertisement.campaignId} mediaUrl={advertisement.media_link} addUUID={`${advertisement.id}-entity-${advertisement.position}`} destinationLink={advertisement.url} mediaType={advertisement.media_type} classOption={classOptions.advertisementClubArticle}/>}


                </div>
              
            </div>
        </div>
    )
}

ClubArticlesSection.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.shape(ArticlePreviewProps)).isRequired
}

export default ClubArticlesSection

// extracted by mini-css-extract-plugin
export var content = "club-articles-section-module--content--GmzSB";
export var main = "club-articles-section-module--main--+ldXL";
export var articles = "club-articles-section-module--articles--kCCvq";
export var firstRow = "club-articles-section-module--firstRow--zcJjE";
export var firstRow__item = "club-articles-section-module--firstRow__item--B3QhO";
export var secondRow = "club-articles-section-module--secondRow--pLeHB";
export var secondRow__posts = "club-articles-section-module--secondRow__posts--lgR+3";
export var secondRow__posts__item = "club-articles-section-module--secondRow__posts__item--cVysn";
export var secondRow__advertisement = "club-articles-section-module--secondRow__advertisement--HFPG1";
export var advertisement = "club-articles-section-module--advertisement--TJ131";
export var loadMore = "club-articles-section-module--loadMore--SIalX";
export var loadMore__button = "club-articles-section-module--loadMore__button--P0qMj";
export var articleTitle = "club-articles-section-module--articleTitle--g8IRT";
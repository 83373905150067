import { useI18next } from "gatsby-plugin-react-i18next";
import React, { useEffect, useState } from "react"
import axios from 'axios'
import { urlGgeneratedByLanguage } from "../../../../utils/utils"
import LoadMoreButton from "../../atoms/load-more-button/load-more-button"
import { ArticlesPageCount } from "../../../../pages/main/article";

const LoadMoreArticles = ({entityType, entityId, attachNewArticles, pageCount = 0}) => {
    const [hasArticles, setHasArticles] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);

    const {language } = useI18next();

    useEffect(() => {
      if(pageCount < ArticlesPageCount) {
          setHasArticles(false);
      }
    })  

    const loadArticles = () => {
        let newCurrentPage = currentPage + 1
        setCurrentPage(newCurrentPage)
        axios.get(`${urlGgeneratedByLanguage(language)}/api/view/articles?_format=json&${entityType}=${entityId}&page=${newCurrentPage}`).then(response => {
          const responseData = response?.data
          const articles = responseData?.data

          attachNewArticles(articles)
          if(responseData.page_count != 10) {
            setHasArticles(false)
          }
        })
    }

    return <>
        {hasArticles && <LoadMoreButton click={loadArticles}/>}
    </>
}


export default LoadMoreArticles
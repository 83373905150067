import React from 'react';
import * as styles from './load-more-button.module.scss'
import { useTranslation } from 'gatsby-plugin-react-i18next';

const LoadMoreButton = ({click}) => {
    return <div className={styles.loadMore}>
            <button className={`${styles.loadMore__button}`} onClick={click}>{useTranslation().t('articles.more')}</button>
        </div>
}

export default LoadMoreButton;